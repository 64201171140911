import { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'

import setPartSaleInfo from '../../../modules/redux/partList/setPartSaleInfo'
import { useSelector } from '../../../modules/store/customSelector'
import { Part } from '../../../types/part/partTypes'
import SelectableMartketplaceList from '../../_atoms/SelectableMarketplaceList/SelectableMartketplaceList'

import styles from './styles.module.scss'

interface IProps {
    part: Part | null
    isOpen: boolean
    closeModal: () => void
}

const ModalSellPart = ({ part, isOpen, closeModal }: IProps) => {
    const dispatch = useDispatch()

    const [selectedMarketplace, setSelectedMarketplace] = useState<string | null>(null)
    const [price, setPrice] = useState<string | undefined>(part?.price.toString())

    const marketPlacesInfo = useSelector(state => state.sales.marketplaces.info)

    const onCloseModal = () => {
        closeModal()
    }

    const handleComponentClick = (index: string) => {
        setSelectedMarketplace(index)
    }

    const confirmSoldPart = () => {
        if (!price || !part || !selectedMarketplace) return

        dispatch(setPartSaleInfo(part, Number(price), selectedMarketplace))
        closeModal()
    }

    return (
        <>
            <Modal className={styles.modalSellPart} show={isOpen} onHide={onCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{part?.customTitle || part?.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4 className={styles.title}>Цена</h4>
                    <Form.Control
                        value={price}
                        type="number"
                        id="price"
                        aria-describedby="price"
                        className={styles.priceField}
                        placeholder="3 000 P"
                        onChange={e => setPrice(e.target.value)}
                    />
                    <h4 className={styles.title}>Площадка продажи</h4>
                    <SelectableMartketplaceList
                        componentData={marketPlacesInfo}
                        selectedComponent={selectedMarketplace}
                        onComponentClick={handleComponentClick}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <div className={styles.buttonGroup}>
                        <Button
                            size="lg"
                            variant="primary"
                            onClick={confirmSoldPart}
                            disabled={!price || !selectedMarketplace}
                        >
                            Продано
                        </Button>
                        <Button size="lg" variant="secondary" onClick={onCloseModal}>
                            Отменить
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalSellPart

import axios from 'axios'
import { Dispatch } from 'redux'

import { Document } from '../../../types/document/documentTypes'
import { RootState } from '../../store/rootReducer'
import { Cashier } from '../../store/user/userReducer'

export const TAX_RATES: Record<string, number> = {
    VAT0: 0,
    VAT10: 10 / 110,
    VAT20: 20 / 120,
}

const printFiscalReceipt =
    (document: Document, cashier: Cashier) =>
    async (dispatch: Dispatch, getState: () => RootState): Promise<void | string> => {
        const {
            extra_data: { parts, number, buyerName, buyerTelephone },
        } = document

        const soldParts: { name: string; quantity: number; price: number; tax: string }[] = []
        let totalAmount = 0
        let totalTax = 0
        const taxRate = TAX_RATES[cashier.tax] ?? 0

        parts.forEach(part => {
            totalAmount += part.price
            soldParts.push({
                name: part.customTitle || part.title,
                quantity: 1,
                price: part.price,
                tax: cashier.tax,
            })

            // const priceMap = new Map<number, number>()

            // priceMap.set(part.price, (priceMap.get(part.price) ?? 0) + 1)
            //     totalAmount += part.price
            // if (part.isSold) {
            //     priceMap.set(part.price, (priceMap.get(part.price) ?? 0) + 1)
            //     totalAmount += part.price
            // }

            // part.extraData?.soldQtyArr?.forEach(item => {
            //     priceMap.set(item.price, (priceMap.get(item.price) ?? 0) + 1)
            //     totalAmount += item.price
            // })

            // priceMap.forEach((quantity, price) => {
            //     soldParts.push({
            //         name: part.title,
            //         quantity,
            //         price,
            //         tax: cashier.tax,
            //     })
            // })
        })

        totalTax = totalAmount * taxRate
        const totalSubtotal = totalAmount - totalTax

        const requestBody = {
            cash_register_id: '',
            transaction_id: number,
            operator: `${cashier.familyName} ${cashier.name} ${cashier.surname}`,
            items: soldParts,
            total: {
                subtotal: totalSubtotal.toFixed(2),
                tax: totalTax.toFixed(2),
                total_amount: totalAmount,
            },
            payment: {
                method: 'cash',
                amount: totalAmount,
            },
            customer: {
                name: buyerName,
                phone: buyerTelephone,
            },
            receipt_options: {
                print_copies: 1,
                // phone: buyerTelephone,
                // email: 'asdasdf@yandex.ru',
            },
        }
        try {
            const response = await axios.post<{ error_description?: string }>(
                cashier.hostPort + '/api/v1/atol/print',
                requestBody,
            )

            if (response.data?.error_description) {
                return response.data.error_description
            }

            throw new Error('No result in response')
        } catch (e) {
            console.error('print fiscal receipt failed', e)
            throw e
        }
    }

export default printFiscalReceipt

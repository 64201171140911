import React from 'react'
import { InfoCircle } from 'react-bootstrap-icons'

import { colors, fontSizes } from '../../../constants/salesStyles'
import HoverOverlay from '../../_atoms/HoverOverlay/HoverOverlay'

import styles from './styles.module.scss'

interface IProps {
    text?: string
    widthInPixels?: number
    marginLeftPixels?: number
    marginRightPixels?: number
}

const SalesTableDataInfo = ({ text, widthInPixels, marginLeftPixels, marginRightPixels }: IProps) => (
    <HoverOverlay tooltipText={text} maxWidthInPixels={widthInPixels ? widthInPixels : 230}>
        <div
            className={styles.icon}
            style={{
                marginLeft: marginLeftPixels || marginLeftPixels === 0 ? marginLeftPixels : 5,
                marginRight: marginRightPixels || marginRightPixels === 0 ? marginRightPixels : 5,
            }}
        >
            <InfoCircle size={fontSizes.m} color={colors.grayDark} />
        </div>
    </HoverOverlay>
)

export default SalesTableDataInfo

import { Reducer } from 'redux'

import { ShiftStatus } from '../../../components/_orgamisms/CashierStatus/CashierStatus'
import { SelectOptionLocal } from '../../../types/common/commonTypes'
import { Document, EDocumentType, IncomingInvoice } from '../../../types/document/documentTypes'
import { storeDispatchTypes } from '../storeDispatchTypes'

interface documentList {
    data: IncomingInvoice[] & Document[]
    total: number
    dataCount: number
}

export interface stateTypeDocuments {
    document: IncomingInvoice | Document | null
    documentList: documentList
    isLoading: boolean
    filters: {
        documentType?: EDocumentType
        page: number
    }
    loaders: {
        isDocumentCreated: boolean
        isDocumentDownloaded: boolean
        isVendorDataCreated: boolean
        isDocumentUpdated: boolean
        documents: boolean
    }
    documents: SelectOptionLocal[]
    shiftStatus: ShiftStatus
}

const initialState = {
    document: null,
    documentList: {
        data: [],
        total: 0,
        dataCount: 0,
    },
    isLoading: false,
    filters: { page: 1 },
    loaders: {
        isDocumentCreated: false,
        isDocumentDownloaded: false,
        isVendorDataCreated: false,
        isDocumentUpdated: false,
        documents: false,
    },
    documents: [],
    shiftStatus: ShiftStatus.closed,
}

const createDocumentListReducer: Reducer<stateTypeDocuments> = (state = initialState, action) => {
    const getUpdatedDocumentIndex = (documentId: string) =>
        state.documentList.data.findIndex(document => document.id === documentId)

    const updateDataFieldById = (documentId: string, field: string, value: string | number | boolean) =>
        state.documentList.data.map((document, index) => {
            if (index === getUpdatedDocumentIndex(documentId)) {
                return { ...document, [field]: value }
            }
            return { ...document }
        })
    const removeItemFromList = (field: 'isDeleted') => {
        const draftList = [...state.documentList.data]
        const filteredList = draftList.filter(item => item[field] !== true)
        return { ...state, documentList: { ...state.documentList, data: filteredList } }
    }

    switch (action.type) {
        case storeDispatchTypes.setDocumentList: {
            return {
                ...state,
                documentList: action.value,
            }
        }
        case storeDispatchTypes.setDocumentListLoading: {
            return {
                ...state,
                isLoading: action.value,
            }
        }
        case storeDispatchTypes.setDocumentsLoader: {
            return {
                ...state,
                loaders: {
                    ...state.loaders,
                    [action.value.field]: action.value.value,
                },
            }
        }
        case storeDispatchTypes.setDocumentDeletedStatus: {
            const draftData = updateDataFieldById(action.value.id, 'isDeleted', action.value.isDeleted)
            return { ...state, documentList: { ...state.documentList, data: draftData } }
        }
        case storeDispatchTypes.removeDeletedDocumentFormList: {
            return removeItemFromList('isDeleted')
        }
        case storeDispatchTypes.resetFiltersDocuments: {
            return { ...state, filters: { page: 1 } }
        }
        case storeDispatchTypes.setPageFilterDocuments: {
            return { ...state, filters: { ...state.filters, page: action.value.page } }
        }
        case storeDispatchTypes.setAllDocuments: {
            return { ...state, documents: action.value.documents }
        }
        case storeDispatchTypes.setDocument: {
            return { ...state, document: action.value }
        }
        case storeDispatchTypes.setShiftStatus: {
            return { ...state, shiftStatus: action.value }
        }
        default: {
            return state
        }
    }
}

export default createDocumentListReducer

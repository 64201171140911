import { Dispatch } from 'redux'

import anchor from '../../../constants/anchor'
import { Document } from '../../../types/document/documentTypes'
import { apiDocument } from '../../api/apiInstance'
import { RootState } from '../../store/rootReducer'

const getDocumentById =
    (id: string) =>
    async (dispatch: Dispatch, getState: () => RootState): Promise<Document | undefined> => {
        const { userData } = getState()

        try {
            const { data } = await apiDocument.post(
                `/getDocument/${id}`,
                {
                    userId: userData.smsAuth.userId,
                },
                {
                    headers: {
                        Authorization: `${anchor} ${localStorage.getItem('accessToken')}`,
                    },
                },
            )

            if (data?.data) {
                return data.data
            }
        } catch (e) {
            console.error('get document by id error', e)
        }
    }

export default getDocumentById

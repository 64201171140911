import React, { useEffect, useState } from 'react'
import { Badge, Button, Stack } from 'react-bootstrap'
import { ExclamationTriangleFill, CheckCircleFill } from 'react-bootstrap-icons'

import axios from 'axios'

import { toast } from 'react-toastify'
import { useDispatch } from '../../../modules/store/customDispatch'
import { useSelector } from '../../../modules/store/customSelector'
import { storeDispatchTypes } from '../../../modules/store/storeDispatchTypes'

export type ConnectionStatus = 'connected' | 'error'
export enum ShiftStatus {
    opened = 'opened',
    closed = 'closed',
    expired = 'expired',
}

export interface DeviceStatus {
    blocked: boolean
    cashDrawerOpened: boolean
    coverOpened: boolean
    currentDateTime: string // ISO-строка с датой/временем
    fiscal: boolean
    fnFiscal: boolean
    fnPresent: boolean
    paperPresent: boolean
    shift: ShiftStatus
}

export interface StatusResponse {
    data?: {
        deviceStatus?: DeviceStatus
    }
    duration: number
}

export interface AtolResponse {
    result?: string
    result_code?: number
    result_recommendation?: string
    duration: number
}

/**
 * Компонент CashierStatus
 * 1) Опрос статуса по API каждые 10 сек
 * 2) Отрисовка бейджа статуса соединения, статуса смены
 * 3) Кнопки открыть/закрыть смену и переподключиться при ошибке
 */
const CashierStatus: React.FC = () => {
    const dispatch = useDispatch()
    const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>('error')
    const [shiftStatus, setShiftStatus] = useState<ShiftStatus>(ShiftStatus.closed)
    const [isLoading, setIsLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')

    const cashierInfo = useSelector(state => state.userData.cashierInfo)

    // Функция опроса статуса
    const pollStatus = async () => {
        try {
            const res = await axios.get<StatusResponse>(cashierInfo?.cashier.hostPort + '/api/v1/atol/status')
            // Если получили статус 200, считаем, что всё в порядке
            if (res.status === 200) {
                setConnectionStatus('connected')
                // Если бэкенд возвращает что-то вроде res.data.result.shift = 'open'/'closed'
                // Можно проверить:
                console.log('/api/v1/atol/status res', res.data.data?.deviceStatus?.shift)
                if (res.data.data && res.data.data.deviceStatus) {
                    setShiftStatus(res.data.data.deviceStatus?.shift)
                } else {
                    // Если сервер не отдает явно 'open'/'closed',
                    // можете поставить значение по умолчанию:
                    setShiftStatus(ShiftStatus.closed)
                }
                setErrorMsg('')
            } else {
                // Любой другой статус считаем ошибкой
                setConnectionStatus('error')
                setErrorMsg(`Ошибка соединения: ${res.status}`)
            }
        } catch (err: any) {
            setConnectionStatus('error')
            setErrorMsg(err.message || 'Ошибка при запросе статуса')
        }
    }

    useEffect(() => {
        if (cashierInfo?.connectCashbox && cashierInfo?.cashier.type == 'ATOL') {
            // Первый вызов сразу
            pollStatus()
            // Запуск опроса каждые 5 секунд
            const intervalId = setInterval(pollStatus, 30000)

            // Очистка при размонтировании или если connectCashbox станет false
            return () => clearInterval(intervalId)
        }
        // Если connectCashbox === false или undefined,
        // то никакого интервала не заводим
    }, [cashierInfo?.connectCashbox])

    // Обработчик открытия смены
    const handleOpenShift = async () => {
        setIsLoading(true)
        try {
            await axios.post(cashierInfo?.cashier.hostPort + '/api/v1/atol/open_shift', {
                cash_register_id: '',
                operator: [
                    cashierInfo?.cashier?.familyName,
                    cashierInfo?.cashier?.name,
                    cashierInfo?.cashier?.surname,
                ].join(' '),
            })
            // После успешного открытия смены можно обновить статус
            setShiftStatus(ShiftStatus.opened)
            setErrorMsg('')
        } catch (err: any) {
            setErrorMsg(err.message || 'Ошибка при открытии смены')
        } finally {
            setIsLoading(false)
        }
    }

    // Обработчик закрытия смены
    const handleCloseShift = async () => {
        setIsLoading(true)
        try {
            await axios.post(cashierInfo?.cashier.hostPort + '/api/v1/atol/close_shift', {
                cash_register_id: '',
                operator: [
                    cashierInfo?.cashier?.familyName,
                    cashierInfo?.cashier?.name,
                    cashierInfo?.cashier?.surname,
                ].join(' '),
            })
            // После успешного закрытия смены можно обновить статус
            setShiftStatus(ShiftStatus.closed)
            setErrorMsg('')
        } catch (err: any) {
            setErrorMsg(err.message || 'Ошибка при закрытии смены')
        } finally {
            setIsLoading(false)
        }
    }

    // Обработчик закрытия смены
    const handleCancelLastReceipt = async () => {
        setIsLoading(true)
        try {
            const res = await axios.get<AtolResponse>(cashierInfo?.cashier.hostPort + '/api/v1/atol/cancel_last_receipt')
            toast.info(res.data.result)
            setErrorMsg('')
        } catch (err: any) {
            setErrorMsg(err.message || 'Ошибка при закрытии смены')
        } finally {
            setIsLoading(false)
        }
    }

    // Обработчик переподключения
    const handleReconnect = async () => {
        setIsLoading(true)
        try {
            await axios.get(cashierInfo?.cashier.hostPort + '/api/v1/atol/reconnect')
            // После успешного переподключения обновим статус
            setErrorMsg('')
            pollStatus()
        } catch (err: any) {
            setErrorMsg(err.message || 'Ошибка переподключения')
        } finally {
            setIsLoading(false)
        }
    }

    // Рендер бейджа статуса соединения
    const renderConnectionBadge = () => {
        if (connectionStatus === 'connected') {
            return (
                <Badge bg="success" className="me-2">
                    <CheckCircleFill className="me-1" />
                    Соединение: OK
                </Badge>
            )
        } else {
            return (
                <Badge bg="danger" className="me-2">
                    <ExclamationTriangleFill className="me-1" />
                    Соединение: Error
                </Badge>
            )
        }
    }

    // Рендер бейджа статуса смены (только если соединение есть)
    const renderShiftBadge = () => {
        if (shiftStatus === ShiftStatus.opened) {
            return (
                <Badge bg="success" className="me-2">
                    Смена: открыта
                </Badge>
            )
        } else if (shiftStatus === ShiftStatus.expired) {
            return (
                <Badge bg="secondary" className="me-2">
                    Смена: истекла
                </Badge>
            )
        } else if (shiftStatus === ShiftStatus.closed) {
            return (
                <Badge bg="secondary" className="me-2">
                    Смена: закрыта
                </Badge>
            )
        }
    }

    console.log('shiftStatus', shiftStatus)

    // Рендер кнопок открыть/закрыть смену
    const renderShiftActions = () => {
        if (shiftStatus === ShiftStatus.opened || shiftStatus === ShiftStatus.expired) {
            return (<>
                <Button variant="outline-danger" size="sm" onClick={handleCloseShift} disabled={isLoading}>
                    Закрыть смену
                </Button>
            </>
            )
        } else {
            return (
                <Button variant="outline-success" size="sm" onClick={handleOpenShift} disabled={isLoading}>
                    Открыть смену
                </Button>
            )
        }
    }

    const renderCancelAction = () => {
        if (shiftStatus === ShiftStatus.opened) {
            return (<>
                <Button variant="outline-danger" size="sm" onClick={handleCancelLastReceipt} disabled={isLoading}>
                    Отмена последнего чека
                </Button>
            </>
            )
        }
    }

    // Если соединение не установлено – показать кнопку переподключиться
    const renderReconnectButton = () => (
        <Button variant="warning" size="sm" onClick={handleReconnect} disabled={isLoading}>
            Переподключиться
        </Button>
    )

    useEffect(() => {
        dispatch({
            type: storeDispatchTypes.setShiftStatus,
            value: shiftStatus,
        })
    }, [dispatch, shiftStatus])

    return (
        <>
            {cashierInfo?.connectCashbox && cashierInfo?.cashier.type == 'ATOL' ? (
                <div>
                    <h5>Статус кассы</h5>
                    <div>
                        {cashierInfo?.cashier?.familyName} {cashierInfo?.cashier?.name} {cashierInfo?.cashier?.surname}
                    </div>
                    <Stack direction="horizontal" gap={3} className="align-items-center">
                        {renderConnectionBadge()}
                        {connectionStatus === 'connected' && (
                            <>
                                {renderShiftBadge()}
                                {renderShiftActions()}
                                {renderCancelAction()}
                            </>
                        )}
                        {connectionStatus === 'error' && renderReconnectButton()}
                    </Stack>

                    {errorMsg && (
                        <div className="mt-2 text-danger">
                            <small>{errorMsg}</small>
                        </div>
                    )}
                </div>
            ) : null}
        </>
    )
}

export default CashierStatus

import { Dispatch } from 'redux'

import anchor from '../../../constants/anchor'
import { PartListSearchParams } from '../../../types/part/partTypes'
import { apiInstance } from '../../api/apiInstance'
import { RootState } from '../../store/rootReducer'
import { storeDispatchTypes } from '../../store/storeDispatchTypes'

interface IPararms {
    partsId?: string[]
    warehouseId?: string
    row?: string
    shelf?: string
    place?: string
    searchParams?: PartListSearchParams
}

const setPartsWarehousePosition =
    (params: IPararms) =>
    async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        const { userData } = getState()

        const reqParams = {
            userId: userData.smsAuth.userId,
            partsId: params.partsId,
            ...(params.warehouseId && { warehouseId: params.warehouseId }),
            ...(params.row && { row: params.row }),
            ...(params.shelf && { shelf: params.shelf }),
            ...(params.place && { place: params.place }),
            ...(params.searchParams && { searchParams: params.searchParams }),
        }

        const config = {
            headers: {
                Authorization: `${anchor} ${localStorage.getItem('accessToken')}`,
            },
        }

        dispatch({
            type: storeDispatchTypes.setWarehousesLoading,
            value: true,
        })

        try {
            const {
                data: { status },
            } = await apiInstance.post('/warehouses/setPartsWarehousePosition', reqParams, config)
            if (status === 'ok') {
                dispatch({
                    type: storeDispatchTypes.setWarehousesLoading,
                    value: false,
                })
            }
        } catch (e) {
            console.error('set parts warehousePosition failed', e)
            throw e
        }

        dispatch({
            type: storeDispatchTypes.setWarehousesLoading,
            value: false,
        })
    }

export default setPartsWarehousePosition

import { fieldTypes, valueTypes } from '../../../types/common/commonTypes'
import { partFields } from '../../../types/part/partTypes'

export const initialPartState = {
    fields: [
        {
            field: partFields.warehouseTitle,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.string,
            value: '',
            title: 'Склад',
            specialProps: {
                column: 1,
            },
        },
        // {
        //     field: partFields.warehouseRow,
        //     fieldType: fieldTypes.input,
        //     valueType: valueTypes.string,
        //     value: '',
        //     title: 'Номер ряда',
        //     specialProps: {
        //         placeholder: 'введите номер ряда',
        //         fieldParent: partFields.warehouseTitle,
        //         column: 1,
        //     },
        // },
        // {
        //     field: partFields.warehouseShelf,
        //     fieldType: fieldTypes.input,
        //     valueType: valueTypes.string,
        //     value: '',
        //     title: 'Номер полки',
        //     specialProps: {
        //         placeholder: 'введите номер полки',
        //         fieldParent: partFields.warehouseTitle,
        //         column: 1,
        //     },
        // },
        // {
        //     field: partFields.warehousePlace,
        //     fieldType: fieldTypes.input,
        //     valueType: valueTypes.string,
        //     value: '',
        //     title: 'Номер места',
        //     specialProps: {
        //         placeholder: 'введите номер места',
        //         fieldParent: partFields.warehouseTitle,
        //         column: 1,
        //     },
        // },
    ],
}

import { useCallback, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Trash3 } from 'react-bootstrap-icons'
import { toast } from 'react-toastify'

import DowloadDocument from '../../../modules/redux/documentList/DowloadDocument'
import getDocumentById from '../../../modules/redux/documentList/GetDocumentById'
import printFiscalReceipt from '../../../modules/redux/documentList/PrintFiscalReceipt'
import setDocumentDeletedStatus from '../../../modules/redux/documentList/SetDocumentDeletedStatus'
import { useDispatch } from '../../../modules/store/customDispatch'
import { useSelector } from '../../../modules/store/customSelector'
import { EDocumentType } from '../../../types/document/documentTypes'
import { ShiftStatus } from '../../_orgamisms/CashierStatus/CashierStatus'
import HoverOverlay from '../HoverOverlay/HoverOverlay'
import Loader from '../Loader/Loader'

interface IProps {
    documentId: string
    type: EDocumentType
}

const CellButtonsDocuments = ({ documentId, type }: IProps) => {
    const dispatch = useDispatch()
    const [isLoadingReceipt, setIsLoadingReceipt] = useState(false)
    const cashierInfo = useSelector(state => state.userData.cashierInfo)
    const currentCurrency = useSelector(state => state.partList.currentCurrency)
    const isLoadingCurrentCurrency = useSelector(state => state.partList.loaders.currentCurrency)
    const isLoadingDocumentDownloaded = useSelector(state => state.documentList.loaders.isDocumentDownloaded)
    const shiftStatus = useSelector(state => state.documentList.shiftStatus)

    const handleCreateXLS = () => {
        dispatch(DowloadDocument(documentId, type, 'xlsx', currentCurrency))
    }

    const handleIssueReceipt = useCallback(async () => {
        setIsLoadingReceipt(true)
        const document = await dispatch(getDocumentById(documentId))
        try {
            if (document && cashierInfo) {
                const response = await dispatch(printFiscalReceipt(document, cashierInfo.cashier))
                response && toast.info(response)
            }
        } catch {
            toast.error(`Ошибка печатания чека`)
        }
        setIsLoadingReceipt(false)
    }, [cashierInfo?.cashier, dispatch, documentId])

    return (
        <>
            {type === EDocumentType.Receipt &&
                cashierInfo?.cashier.type == 'ATOL' &&
                (isLoadingReceipt ? (
                    <Loader diameterInPx={28} thicknessInPx={3} margin={0} />
                ) : (
                    <Button
                        variant={'success'}
                        size="sm"
                        onClick={handleIssueReceipt}
                        disabled={shiftStatus !== ShiftStatus.opened}
                    >
                        Выбить чек
                    </Button>
                ))}

            {isLoadingCurrentCurrency || isLoadingDocumentDownloaded ? (
                <Loader diameterInPx={28} thicknessInPx={3} margin={0} />
            ) : (
                <HoverOverlay tooltipText={'Скачать XLS'} preventWrapFullWidth>
                    <Button variant={'primary'} style={{ width: 70 }} size="sm" onClick={handleCreateXLS}>
                        XLS
                    </Button>
                </HoverOverlay>
            )}
            <HoverOverlay tooltipText={'Удалено'} preventWrapFullWidth>
                <Button
                    variant="danger"
                    size="sm"
                    onClick={() => dispatch(setDocumentDeletedStatus(documentId, type))}
                    className="d-flex align-items-center"
                >
                    <Trash3 color={'white'} size={18} />
                </Button>
            </HoverOverlay>
        </>
    )
}

export default CellButtonsDocuments

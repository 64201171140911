export default [
    { id: 1, title: 'Новое' },
    { id: 2, title: 'Идеальное' },
    { id: 3, title: 'Хорошее' },
    { id: 4, title: 'Удовлетворительное' },
    { id: 5, title: 'Б/у' },
]

export const partConditionOptions = [
    { value: 1, name: 'Новое' },
    { value: 2, name: 'Идеальное' },
    { value: 3, name: 'Хорошее' },
    { value: 4, name: 'Удовлетворительное' },
]

export const wheelsConditionOptions = [
    { value: 1, name: 'Новое' },
    { value: 5, name: 'Б/у' },
]
